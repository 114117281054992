<template>
  <div>
        <v-label>Настройки холста</v-label>
        <div class="d-flex mb-4">
            <v-text-field
                v-model="settings.pointsOffset"
                type="number"
                label="Растояние между точками (px)"
                class="maxW-150 mr-5"
                @input="onMeasuresSettingsChange"
            />

            <v-text-field
                v-model="settings.pointsPerWidth"
                type="number"
                label="Точек по горизонтали"
                class="maxW-100 mr-5"
                @input="onMeasuresSettingsChange"
            />

            <v-text-field
                v-model="settings.pointsPerHeight"
                type="number"
                label="Точек по вертикали"
                class="maxW-100 mr-5"
                @input="onMeasuresSettingsChange"
            />

            <v-select
                v-model="settings.comparisonType"
                :items="comparisonTypes"
                label="Тип проверки"
                class="maxW-200 mr-5"
            />
        </div>

        <v-label>Инструменты пользователя</v-label>
        <div class="d-flex align-center mb-4">
            <v-checkbox
                v-model="settings.tools"
                label="Фигура"
                :value="toolsList[0]"
                hide-details
                class="mr-5 my-0"
                @change="onToolsSettingsToggle"
            />
            <v-checkbox
                v-model="settings.tools"
                label="Линия"
                :value="toolsList[1]"
                hide-details
                class="mr-5 my-0"
            />
            <v-checkbox
                v-model="settings.tools"
                label="Точка"
                :value="toolsList[2]"
                hide-details
                class="mr-5 my-0"
            />
        </div>

        <template v-if="settings.figure">
            <v-label>Параметры фигуры для отрисовки учеником</v-label>
            <div class="d-flex">
                <v-text-field
                    v-model="settings.figure.width"
                    type="number"
                    label="Ширина прямоугольника/квадрата"
                    hint="Вертикаль фигуры(!)"
                    persistent-hint
                    class="maxW-200 mr-5"
                />

                <v-text-field
                    v-model="settings.figure.length"
                    type="number"
                    label="Длина прямоугольника/квадрата"
                    hint="Горизонталь фигуры(!)"
                    persistent-hint
                    class="maxW-200 mr-5"
                />

                <v-text-field
                    v-model="settings.figure.square"
                    type="number"
                    label="Площадь прямоугольника/квадрата"
                    class="maxW-200 mr-5"
                />

                <v-text-field
                    v-model="settings.figure.perimeter"
                    type="number"
                    label="Периметр прямоугольника/квадрата"
                    class="maxW-200"
                />

            </div>
        </template>
        
        <p v-if="settings.comparisonType" class="mt-4">
            Укажите возможный вариант правильного ответа.<br>Нарисованная вами фигура будет показана по кнопке "Посмотреть ответ".
        </p>
        <p v-else class="mt-4">
            Укажите все варианты правильного ответа.
        </p>

        <v-dialog
            v-model="dialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                >Разметить задание</v-btn>
            </template>
            <grid-setup-editor
                :key="`${settings.pointsOffset}_${settings.pointsPerWidth}_${settings.pointsPerHeight}`"
                v-model="dialog"
                :correct-answer.sync="correctAnswer"
                :presets.sync="settings.preset"
                :points-offset="parseInt(settings.pointsOffset)"
                :points-per-width="parseInt(settings.pointsPerWidth)"
                :points-per-height="parseInt(settings.pointsPerHeight)"
            />
        </v-dialog>
  </div>
</template>

<script>
import interactiveMixin from '@/mixins/interactiveMixin.vue'
import GridSetupEditor from './GridSetupEditor/Index.vue'
import { getDefaultPresets, getFigureDefaultPreset } from './types'

export default {
    mixins: [interactiveMixin],
    components: { GridSetupEditor },
    inject: ['model'],
    data () {
        return {
            correctAnswer: [],
            dialog: false,
            settings: {
                pointsOffset: 20, // px
                pointsPerWidth: 10,
                pointsPerHeight: 10,
                comparisonType: null,
                tools: ['polyline', 'line', 'point'],
                figure: null,
                preset: getDefaultPresets()
            }
        }
    },
    computed: {
        comparisonTypes () {
            return [
                { value: '', text: 'Проверка точного равенства одному из вариантов ответа' },
                { value: 'diagonal', text: 'Построить фигуру без деления на пустой сетке по вершине и диагонали' },
                { value: 'parameters', text: 'Построить фигуру без деления на пустой сетке по заданным параметрам' },
                { value: 'ss-2t24', text: 'Деление на 2 одинаковых квадрата, затем одной линией на 2 треугольника и 2 четырёхугольника' },
                { value: 'sr', text: 'Деление на квадрат и прямоугольник' },
                { value: 't4', text: 'Деление на треугольник и четырёхугольник' },
                { value: '3t', text: 'Деление на 3 треугольника' },
                { value: '2t', text: 'Деление на 2 треугольника' },
                { value: 'tt', text: 'Деление на 2 равных треугольника' },
                { value: '2r', text: 'Деление на 2 неравных прямоугольника' },
                { value: 'rr', text: 'Деление на 2 равных прямоугольника' },
                { value: 'ff', text: 'Деление на 2 равные фигуры' },
                { value: 'fff', text: 'Деление на 3 равные фигуры' },
                { value: '3ts', text: 'Деление на 3 треугольника и квадрат' },
                { value: 'ssss', text: 'Деление на 4 одинаковых квадрата' },
                { value: '2t6', text: 'Деление на 2 треугольника и шестиугольник' },
                { value: 'symmetry', text: 'Симметричное построение' },
            ]
        },
        toolsList () {
            return ['polyline', 'line', 'point']
        }
    },
    created () {
        this.onToolsSettingsToggle()
    },
    methods: {
        beforeUpdate () {
            ['pointsOffset', 'pointsPerWidth', 'pointsPerHeight'].forEach((key) => {
                this.settings[key] = parseInt(this.settings[key])
            })
            if (this.settings.figure) {

                ['width', 'length', 'square', 'perimeter'].forEach((key) => {

                    if (!this.settings.figure[key]) {

                        this.settings.figure[key] = 0
                    }
                    this.settings.figure[key] = parseInt(this.settings.figure[key])
                })
            }
            return {
                correctAnswer: this.correctAnswer,
                settings: this.settings
            }
        },
        onMeasuresSettingsChange () {
            // Обнуляем пресет и ответы, потому настройки позиций придется пересчитывать
            this.settings.preset = getDefaultPresets()
            this.correctAnswer = []
        },
        onToolsSettingsToggle () {
            if (this.settings.tools.includes('polyline') && this.settings.figure === null)
                this.settings.figure = getFigureDefaultPreset()

            if (!this.settings.tools.includes('polyline') && this.settings.figure !== null)
                this.settings.figure = null
        }
    }
}
</script>